<template>
  <div>
    <el-table
      height="695"
      class="table-normal light w-100"
      :data="
        type == 'view' || type == 'add' || type == 'edit'
          ? dataRes
          : dataRes.permissionCustom.length > 0
          ? dataRes.permissionCustom
          : dataRes.role.permission
      "
    >
      <el-table-column min-width="150px">
        <template slot="header">
          <p class="mg-0 text-left">ฟังก์ชัน</p>
        </template>
        <template slot-scope="scope">
          <p class="mg-0 text-left font-18">
            <span
              :class="
                scope.row.pemNum == 6 ||
                scope.row.pemNum == 7 ||
                scope.row.pemNum == 9 ||
                scope.row.pemNum == 10 ||
                scope.row.pemNum == 30 ||
                scope.row.pemNum == 11 ||
                scope.row.pemNum == 12 ||
                scope.row.pemNum == 23 ||
                scope.row.pemNum == 24 ||
                scope.row.pemNum == 25 ||
                scope.row.pemNum == 26
                  ? 'pd-l-5 color-9c'
                  : ''
              "
            >
              {{ scope.row.permissionName }}
            </span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="ดูฟังก์ชันได้" width="120px">
        <template slot-scope="scope">
          <div v-if="type == 'view'">
            <i
              class="fas fa-check-circle font-16 color-light-blue"
              v-if="scope.row.value.view == 1"
            ></i>
            <i class="fas fa-lock font-16 color-c4" v-else></i>
          </div>
          <div v-else>
            <p class="mg-0 text-center">
              <el-radio
                :disabled="
                  scope.row.pemNum == 6 ||
                  scope.row.pemNum == 7 ||
                  scope.row.pemNum == 9 ||
                  scope.row.pemNum == 10 ||
                  scope.row.pemNum == 30 ||
                  scope.row.pemNum == 11 ||
                  scope.row.pemNum == 12 ||
                  scope.row.pemNum == 23 ||
                  scope.row.pemNum == 24 ||
                  scope.row.pemNum == 25 ||
                  scope.row.pemNum == 26
                    ? checkDisabled(scope.row.pemNum, 'tabView')
                    : false
                "
                :class="
                  checkDisabled(scope.row.pemNum, 'tabView') == true
                    ? 'pointer-none'
                    : ''
                "
                v-model="scope.row.value.view"
                :label="1"
                @click.native.prevent="
                  clickUnValue(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab1',
                    scope.row.value.view,
                    scope.$index,
                    'view'
                  )
                "
                @change="
                  checkRadio(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab1'
                  )
                "
              ></el-radio>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120px">
        <template slot="header">
          <p class="mg-0">
            สร้าง แก้ไข<br />
            และลบฟังก์ชันได้
          </p>
        </template>
        <template slot-scope="scope">
          <div v-if="type == 'view'">
            <i
              class="fas fa-check-circle font-16 color-light-blue"
              v-if="scope.row.value.edit == 1"
            ></i>
            <i class="fas fa-lock font-16 color-c4" v-else></i>
          </div>
          <div v-else>
            <p class="mg-0 text-center">
              <el-radio
                :disabled="
                  scope.row.pemNum == 6 ||
                  scope.row.pemNum == 7 ||
                  scope.row.pemNum == 9 ||
                  scope.row.pemNum == 10 ||
                  scope.row.pemNum == 30 ||
                  scope.row.pemNum == 11 ||
                  scope.row.pemNum == 12 ||
                  scope.row.pemNum == 22 ||
                  scope.row.pemNum == 23 ||
                  scope.row.pemNum == 24 ||
                  scope.row.pemNum == 25 ||
                  scope.row.pemNum == 26
                    ? checkDisabled(scope.row.pemNum, 'tabEdit')
                    : false
                "
                :class="
                  checkDisabled(scope.row.pemNum, 'tabEdit') == true
                    ? 'pointer-none'
                    : ''
                "
                v-model="scope.row.value.edit"
                :label="1"
                @click.native.prevent="
                  clickUnValue(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab2',
                    scope.row.value.edit,
                    scope.$index,
                    'edit'
                  )
                "
                @change="
                  checkRadio(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab2'
                  )
                "
              ></el-radio>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ไม่แสดงฟังก์ชัน" width="110px">
        <template slot-scope="scope">
          <div v-if="type == 'view'">
            <i
              class="fas fa-check-circle font-16 color-light-blue"
              v-if="scope.row.value.hide == 1"
            ></i>
            <i class="fas fa-lock font-16 color-c4" v-else></i>
          </div>
          <div v-else>
            <p class="mg-0 text-center">
              <el-radio
                v-model="scope.row.value.hide"
                :label="1"
                :disabled="
                  scope.row.pemNum == 6 ||
                  scope.row.pemNum == 7 ||
                  scope.row.pemNum == 9 ||
                  scope.row.pemNum == 10 ||
                  scope.row.pemNum == 30 ||
                  scope.row.pemNum == 11 ||
                  scope.row.pemNum == 12 ||
                  scope.row.pemNum == 23 ||
                  scope.row.pemNum == 24 ||
                  scope.row.pemNum == 25 ||
                  scope.row.pemNum == 26
                    ? checkDisabled(scope.row.pemNum)
                    : false
                "
                :class="
                  checkDisabled(scope.row.pemNum) == true
                    ? 'pointer-none'
                    : ''
                "
                @click.native.prevent="
                  clickUnValue(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab3',
                    scope.row.value.hide,
                    scope.$index,
                    'hide'
                  )
                "
                @change="
                  checkRadio(
                    type == 'add' ? scope.row._id : scope.row.id,
                    'tab3'
                  )
                "
              ></el-radio>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="is-flex js-end mg-t-4" v-if="type != 'view'">
      <el-button class="font-16 btn-cancle min-100" @click="closeModal">
        ยกเลิก</el-button
      >
      <el-button
        type="primary"
        class="font-16 submit min-100"
        v-if="type == 'edit' || type == 'add'"
        @click="savePermission"
        :disabled="
          nameRole.trim() == '' && typeof nameRole.trim() != 'undefined'
            ? true
            : false
        "
        >บันทึก</el-button
      ><el-button
        type="primary"
        class="font-16 submit min-100"
        v-else
        @click="savePermission"
        >บันทึก</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: ["dataRes", "nameRole", "pageNow", "type"],
  methods: {
    checkRadio(id, tab) {
      let dataFilter = [];
      let arr = [];
      if (this.type == "add" || this.type == "edit") {
        dataFilter = this.dataRes;
        if (this.type == "add") {
          arr = dataFilter.filter((e) => e._id == id);
        } else {
          arr = dataFilter.filter((e) => e.id == id);
        }
      } else {
        if (this.dataRes.permissionCustom.length > 0) {
          dataFilter = this.dataRes.permissionCustom;
        } else {
          dataFilter = this.dataRes.role.permission;
        }
        arr = dataFilter.filter((e) => e.id == id);
      }
      if (arr.length > 0) {
        arr[0].value = {};
        let setView = 0;
        let setEdit = 0;
        let setHide = 0;
        if (tab == "tab1") {
          setView = 1;
          arr[0].status = 1;
        } else if (tab == "tab2") {
          setView = 1;
          setEdit = 1;
          arr[0].status = 2;
        } else if (tab == "tab3") {
          setHide = 1;
          arr[0].status = 3;
        }
        // ถ้าเลือก changeRole เป็น 3 แล้วทั้งหมดจะต้องเป็น 3

        if (arr[0].pemNum == 5) {
          dataFilter.forEach((dashboard) => {
            if (dashboard.pemNum == 6 || dashboard.pemNum == 7) {
              if (arr[0].status == 1) {
                dashboard.value.view = 1;
                dashboard.value.edit = 0;
                dashboard.value.hide = 0;
                dashboard.status = 1;
              } else if (arr[0].status == 2) {
                dashboard.value.view = 1;
                dashboard.value.edit = 1;
                dashboard.value.hide = 0;
                dashboard.status = 2;
              } else if (arr[0].status == 3) {
                dashboard.value.view = 0;
                dashboard.value.edit = 0;
                dashboard.value.hide = 1;
                dashboard.status = 3;
              }
            }
          });
        }
        // ถ้าเลือก user เป็น 3 แล้วทั้งหมดจะต้องเป็น 3
        if (arr[0].pemNum == 8) {
          dataFilter.forEach((dashboard) => {
            if (
              dashboard.pemNum == 9 ||
              dashboard.pemNum == 10 ||
              dashboard.pemNum == 30 ||
              dashboard.pemNum == 11 ||
              dashboard.pemNum == 12
            ) {
              if (arr[0].status == 1) {
                dashboard.value.view = 1;
                dashboard.value.edit = 0;
                dashboard.value.hide = 0;
                dashboard.status = 1;
              } else if (arr[0].status == 2) {
                dashboard.value.view = 1;
                dashboard.value.edit = 1;
                dashboard.value.hide = 0;
                dashboard.status = 2;
              } else if (arr[0].status == 3) {
                dashboard.value.view = 0;
                dashboard.value.edit = 0;
                dashboard.value.hide = 1;
                dashboard.status = 3;
              }
            }
          });
        }
        // ถ้าเลือก dashboard เป็น 3 แล้วทั้งหมดจะต้องเป็น 3
        if (arr[0].pemNum == 22) {
          dataFilter.forEach((dashboard) => {
            if (
              dashboard.pemNum == 23 ||
              dashboard.pemNum == 24 ||
              dashboard.pemNum == 25 ||
              dashboard.pemNum == 26
            ) {
              if (arr[0].status == 1) {
                dashboard.value.view = 1;
                dashboard.value.edit = 0;
                dashboard.value.hide = 0;
                dashboard.status = 1;
              } else if (arr[0].status == 2) {
                dashboard.value.view = 1;
                dashboard.value.edit = 1;
                dashboard.value.hide = 0;
                dashboard.status = 2;
              } else if (arr[0].status == 3) {
                dashboard.value.view = 0;
                dashboard.value.edit = 0;
                dashboard.value.hide = 1;
                dashboard.status = 3;
              }
            }
          });
        }

        // เช็คกรณีที่ภายใน (dashboard || user) เลือก 1 2 3 ให้ (dashboard || user) ตามไปด้วย
        if (
          arr[0].pemNum == 6 ||
          arr[0].pemNum == 7 ||
          arr[0].pemNum == 9 ||
          arr[0].pemNum == 10 ||
          arr[0].pemNum == 30 ||
          arr[0].pemNum == 11 ||
          arr[0].pemNum == 12 ||
          arr[0].pemNum == 23 ||
          arr[0].pemNum == 24 ||
          arr[0].pemNum == 25 ||
          arr[0].pemNum == 26
        ) {
          let numTransfer = null;
          if (arr[0].pemNum == 6 || arr[0].pemNum == 7) {
            numTransfer = 5;
          } else if (
            arr[0].pemNum == 9 ||
            arr[0].pemNum == 10 ||
            arr[0].pemNum == 30 ||
            arr[0].pemNum == 11 ||
            arr[0].pemNum == 12
          ) {
            numTransfer = 8;
          } else if (
            arr[0].pemNum == 23 ||
            arr[0].pemNum == 24 ||
            arr[0].pemNum == 25 ||
            arr[0].pemNum == 26
          ) {
            numTransfer = 22;
          }
          dataFilter.forEach((dashboard) => {
            if (dashboard.pemNum == numTransfer) {
              if (arr[0].status == 1) {
                dashboard.value.view = 1;
                dashboard.value.edit = 0;
                dashboard.value.hide = 0;
                dashboard.status = 1;
              } else if (arr[0].status == 2) {
                dashboard.value.view = 1;
                dashboard.value.edit = 1;
                dashboard.value.hide = 0;
                dashboard.status = 2;
              }
              // else if (arr[0].status == 3) {
              //   dashboard.value.view = 0;
              //   dashboard.value.edit = 0;
              //   dashboard.value.hide = 1;
              //   dashboard.status = 3;
              // }
            }
          });
        }
        let obj = {
          view: setView,
          edit: setEdit,
          hide: setHide,
        };
        arr[0].value = obj;
      }
    },
    savePermission() {
      this.$emit("saveData", this.dataRes);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    clickUnValue(id, tab, val, indexX) {
      let dataNow = [];
      if (this.type == "editUser") {
        dataNow = this.dataRes.role.permission;
      } else if (this.type == "editUserByCustom") {
        dataNow = this.dataRes.permissionCustom;
      } else {
        dataNow = this.dataRes;
      }
      if (val == 0) {
        if (tab == "tab1") {
          dataNow[indexX].value.view = 1;
        } else if (tab == "tab2") {
          dataNow[indexX].value.edit = 1;
        } else if (tab == "tab3") {
          dataNow[indexX].value.hide = 1;
        }
        this.checkRadio(id, tab);
      } else if (val == 1) {
        if (tab == "tab1") {
          dataNow[indexX].value.view = 0;
          // ปิดออกจะได้เช็คเวลา Tik ออก
          if (dataNow[indexX].status == 1) {
            dataNow[indexX].status = 0;
          }
          dataNow.forEach((res) => {
            if (dataNow[indexX].pemNum == 5) {
              if (res.pemNum == 6 || res.pemNum == 7) {
                res.value = {
                  view: 0,
                  edit: res.value.edit,
                  hide: res.value.hide,
                };
              }
            } else if (dataNow[indexX].pemNum == 8) {
              if (
                res.pemNum == 9 ||
                res.pemNum == 10 ||
                res.pemNum == 30 ||
                res.pemNum == 11 ||
                res.pemNum == 12
              ) {
                res.value = {
                  view: 0,
                  edit: res.value.edit,
                  hide: res.value.hide,
                };
              }
            } else if (dataNow[indexX].pemNum == 22) {
              if (
                res.pemNum == 23 ||
                res.pemNum == 24 ||
                res.pemNum == 25 ||
                res.pemNum == 26
              ) {
                res.value = {
                  view: 0,
                  edit: res.value.edit,
                  hide: res.value.hide,
                };
              }
            }
          });
        } else if (tab == "tab2") {
          if (dataNow[indexX].status == 2 && dataNow[indexX].value.view == 1) {
            dataNow[indexX].status = 1;
          }
          dataNow[indexX].value.edit = 0;
          dataNow.forEach((res) => {
            if (dataNow[indexX].pemNum == 5) {
              if (res.pemNum == 6 || res.pemNum == 7) {
                res.value = {
                  view: res.value.view,
                  edit: 0,
                  hide: res.value.hide,
                };
                // ปิดออกจะได้เช็คเวลา Tik ออก
                // if (res.value.view == 1) {
                //   res.status = 1;
                // } else {
                //   res.status = 0;
                // }
              }
            } else if (dataNow[indexX].pemNum == 8) {
              if (
                res.pemNum == 9 ||
                res.pemNum == 10 ||
                res.pemNum == 30 ||
                res.pemNum == 11 ||
                res.pemNum == 12
              ) {
                res.value = {
                  view: res.value.view,
                  edit: 0,
                  hide: res.value.hide,
                };
                // ปิดออกจะได้เช็คเวลา Tik ออก
                // if (res.value.view == 1) {
                //   res.status = 1;
                // } else {
                //   res.status = 0;
                // }
              }
            } else if (dataNow[indexX].pemNum == 22) {
              if (
                res.pemNum == 23 ||
                res.pemNum == 24 ||
                res.pemNum == 25 ||
                res.pemNum == 26
              ) {
                res.value = {
                  view: res.value.view,
                  edit: 0,
                  hide: res.value.hide,
                };
                // ปิดออกจะได้เช็คเวลา Tik ออก
                // if (res.value.view == 1) {
                //   res.status = 1;
                // } else {
                //   res.status = 0;
                // }
              }
            }
          });
        } else if (tab == "tab3") {
          dataNow[indexX].value.hide = 0;
          dataNow[indexX].status = 0;

          dataNow.forEach((res) => {
            if (dataNow[indexX].pemNum == 5) {
              if (res.pemNum == 6 || res.pemNum == 7) {
                res.value = {
                  view: 0,
                  edit: 0,
                  hide: 0,
                };
                res.status = 0;
              }
            } else if (dataNow[indexX].pemNum == 8) {
              if (
                res.pemNum == 9 ||
                res.pemNum == 10 ||
                res.pemNum == 30 ||
                res.pemNum == 11 ||
                res.pemNum == 12
              ) {
                res.value = {
                  view: 0,
                  edit: 0,
                  hide: 0,
                };
                res.status = 0;
              }
            } else if (dataNow[indexX].pemNum == 22) {
              if (
                res.pemNum == 23 ||
                res.pemNum == 24 ||
                res.pemNum == 25 ||
                res.pemNum == 26
              ) {
                res.value = {
                  view: 0,
                  edit: 0,
                  hide: 0,
                };
                res.status = 0;
              }
            }
          });
        }
      }
      // id, tab
      // console.log(dataNow[indexX])
      // console.log(indexX);
      // console.log(val);
      // if (val == 1) {
      //   if (typeTab == "view") {

      // this.dataRes[0].value.view = 0;
      // this.dataRes[1].value.view = 1;
      // this.dataRes[1].value.edit = 1;
      // this.dataRes[1].value.hide = 0;
      // this.dataRes[1].status = 0;
      //   }
      //   // val = 0
      // }
      // console.log(val)
    },
    checkDisabled(num, tabName) {
      let dataFilter = [];
      let arrUser = [];
      let arrDashboard = [];
      let arrSuperAdmin = [];
      if (this.type == "add" || this.type == "edit") {
        dataFilter = this.dataRes;
        arrSuperAdmin = dataFilter.filter(
          (e) => e.pemNum == 5 && e.status == 0
        );
        arrUser = dataFilter.filter((e) => e.pemNum == 8 && e.status == 0);
        arrDashboard = dataFilter.filter(
          (e) => e.pemNum == 22 && e.status == 0
        );
      } else {
        if (this.dataRes.permissionCustom.length > 0) {
          dataFilter = this.dataRes.permissionCustom;
        } else {
          dataFilter = this.dataRes.role.permission;
        }
        arrSuperAdmin = dataFilter.filter(
          (e) => e.pemNum == 5 && e.status == 0
        );
        arrUser = dataFilter.filter((e) => e.pemNum == 8 && e.status == 0);
        arrDashboard = dataFilter.filter(
          (e) => e.pemNum == 22 && e.status == 0
        );
      }
      let result1 = null;
      let result2 = null;
      let result3 = null;
      if (num == 6 || num == 7) {
        if (arrSuperAdmin.length > 0) {
          result3 = true;
        } else {
          result3 = false;
        }
        return result3;
      } else if (num == 9 || num == 10 || num == 30 || num == 11 || num == 12) {
        if (arrUser.length > 0) {
          result1 = true;
        } else {
          result1 = false;
        }
        return result1;
      } else if (
        num == 22 ||
        num == 23 ||
        num == 24 ||
        num == 25 ||
        num == 26
      ) {
        if (num == 23 || num == 24 || num == 25 || num == 26) {
          if (arrDashboard.length > 0) {
            result2 = true;
          } else {
            if (tabName == "tabEdit") {
              result2 = true;
            } else {
              result2 = false;
            }
          }
        } else if (num == 22) {
          if (tabName == "tabEdit") {
            result2 = true;
          }
        }
        return result2;
      }
    },
  },
};
</script>