var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticClass:"table-normal light w-100",attrs:{"height":"695","data":_vm.type == 'view' || _vm.type == 'add' || _vm.type == 'edit'
        ? _vm.dataRes
        : _vm.dataRes.permissionCustom.length > 0
        ? _vm.dataRes.permissionCustom
        : _vm.dataRes.role.permission}},[_c('el-table-column',{attrs:{"min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"mg-0 text-left font-18"},[_c('span',{class:scope.row.pemNum == 6 ||
              scope.row.pemNum == 7 ||
              scope.row.pemNum == 9 ||
              scope.row.pemNum == 10 ||
              scope.row.pemNum == 30 ||
              scope.row.pemNum == 11 ||
              scope.row.pemNum == 12 ||
              scope.row.pemNum == 23 ||
              scope.row.pemNum == 24 ||
              scope.row.pemNum == 25 ||
              scope.row.pemNum == 26
                ? 'pd-l-5 color-9c'
                : ''},[_vm._v(" "+_vm._s(scope.row.permissionName)+" ")])])]}}])},[_c('template',{slot:"header"},[_c('p',{staticClass:"mg-0 text-left"},[_vm._v("ฟังก์ชัน")])])],2),_c('el-table-column',{attrs:{"label":"ดูฟังก์ชันได้","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type == 'view')?_c('div',[(scope.row.value.view == 1)?_c('i',{staticClass:"fas fa-check-circle font-16 color-light-blue"}):_c('i',{staticClass:"fas fa-lock font-16 color-c4"})]):_c('div',[_c('p',{staticClass:"mg-0 text-center"},[_c('el-radio',{class:_vm.checkDisabled(scope.row.pemNum, 'tabView') == true
                  ? 'pointer-none'
                  : '',attrs:{"disabled":scope.row.pemNum == 6 ||
                scope.row.pemNum == 7 ||
                scope.row.pemNum == 9 ||
                scope.row.pemNum == 10 ||
                scope.row.pemNum == 30 ||
                scope.row.pemNum == 11 ||
                scope.row.pemNum == 12 ||
                scope.row.pemNum == 23 ||
                scope.row.pemNum == 24 ||
                scope.row.pemNum == 25 ||
                scope.row.pemNum == 26
                  ? _vm.checkDisabled(scope.row.pemNum, 'tabView')
                  : false,"label":1},on:{"change":function($event){return _vm.checkRadio(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab1'
                )}},nativeOn:{"click":function($event){$event.preventDefault();return _vm.clickUnValue(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab1',
                  scope.row.value.view,
                  scope.$index,
                  'view'
                )}},model:{value:(scope.row.value.view),callback:function ($$v) {_vm.$set(scope.row.value, "view", $$v)},expression:"scope.row.value.view"}})],1)])]}}])}),_c('el-table-column',{attrs:{"width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type == 'view')?_c('div',[(scope.row.value.edit == 1)?_c('i',{staticClass:"fas fa-check-circle font-16 color-light-blue"}):_c('i',{staticClass:"fas fa-lock font-16 color-c4"})]):_c('div',[_c('p',{staticClass:"mg-0 text-center"},[_c('el-radio',{class:_vm.checkDisabled(scope.row.pemNum, 'tabEdit') == true
                  ? 'pointer-none'
                  : '',attrs:{"disabled":scope.row.pemNum == 6 ||
                scope.row.pemNum == 7 ||
                scope.row.pemNum == 9 ||
                scope.row.pemNum == 10 ||
                scope.row.pemNum == 30 ||
                scope.row.pemNum == 11 ||
                scope.row.pemNum == 12 ||
                scope.row.pemNum == 22 ||
                scope.row.pemNum == 23 ||
                scope.row.pemNum == 24 ||
                scope.row.pemNum == 25 ||
                scope.row.pemNum == 26
                  ? _vm.checkDisabled(scope.row.pemNum, 'tabEdit')
                  : false,"label":1},on:{"change":function($event){return _vm.checkRadio(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab2'
                )}},nativeOn:{"click":function($event){$event.preventDefault();return _vm.clickUnValue(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab2',
                  scope.row.value.edit,
                  scope.$index,
                  'edit'
                )}},model:{value:(scope.row.value.edit),callback:function ($$v) {_vm.$set(scope.row.value, "edit", $$v)},expression:"scope.row.value.edit"}})],1)])]}}])},[_c('template',{slot:"header"},[_c('p',{staticClass:"mg-0"},[_vm._v(" สร้าง แก้ไข"),_c('br'),_vm._v(" และลบฟังก์ชันได้ ")])])],2),_c('el-table-column',{attrs:{"label":"ไม่แสดงฟังก์ชัน","width":"110px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.type == 'view')?_c('div',[(scope.row.value.hide == 1)?_c('i',{staticClass:"fas fa-check-circle font-16 color-light-blue"}):_c('i',{staticClass:"fas fa-lock font-16 color-c4"})]):_c('div',[_c('p',{staticClass:"mg-0 text-center"},[_c('el-radio',{class:_vm.checkDisabled(scope.row.pemNum) == true
                  ? 'pointer-none'
                  : '',attrs:{"label":1,"disabled":scope.row.pemNum == 6 ||
                scope.row.pemNum == 7 ||
                scope.row.pemNum == 9 ||
                scope.row.pemNum == 10 ||
                scope.row.pemNum == 30 ||
                scope.row.pemNum == 11 ||
                scope.row.pemNum == 12 ||
                scope.row.pemNum == 23 ||
                scope.row.pemNum == 24 ||
                scope.row.pemNum == 25 ||
                scope.row.pemNum == 26
                  ? _vm.checkDisabled(scope.row.pemNum)
                  : false},on:{"change":function($event){return _vm.checkRadio(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab3'
                )}},nativeOn:{"click":function($event){$event.preventDefault();return _vm.clickUnValue(
                  _vm.type == 'add' ? scope.row._id : scope.row.id,
                  'tab3',
                  scope.row.value.hide,
                  scope.$index,
                  'hide'
                )}},model:{value:(scope.row.value.hide),callback:function ($$v) {_vm.$set(scope.row.value, "hide", $$v)},expression:"scope.row.value.hide"}})],1)])]}}])})],1),(_vm.type != 'view')?_c('div',{staticClass:"is-flex js-end mg-t-4"},[_c('el-button',{staticClass:"font-16 btn-cancle min-100",on:{"click":_vm.closeModal}},[_vm._v(" ยกเลิก")]),(_vm.type == 'edit' || _vm.type == 'add')?_c('el-button',{staticClass:"font-16 submit min-100",attrs:{"type":"primary","disabled":_vm.nameRole.trim() == '' && typeof _vm.nameRole.trim() != 'undefined'
          ? true
          : false},on:{"click":_vm.savePermission}},[_vm._v("บันทึก")]):_c('el-button',{staticClass:"font-16 submit min-100",attrs:{"type":"primary"},on:{"click":_vm.savePermission}},[_vm._v("บันทึก")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }